import React from 'react';

const Footer = () => {
  return (
    <footer className='main-footer text-center'>
      <strong>Copyright © 2024.</strong>
    </footer>
  );
};

export default Footer;
