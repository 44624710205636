import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import APIInvoke from '../../utils/APIInvoke';

const apiInvoke = new APIInvoke();

const Login = () => {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({
    email: '',
    password: '',
  });
  const { idUser, password } = usuario;

  const onChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    iniciarSesion();
  };

  useEffect(() => {
    document.getElementById('idUser').focus();
  }, []);

  const iniciarSesion = async () => {
    const data = {
      email: usuario.idUser,
      password: usuario.password,
    };
    const response = await apiInvoke.invokePOST(`/auth/login`, data);
    const mensaje = response.message;

    if (mensaje === 'Invalid email or password') {
      const msg = 'No fue posible iniciar sesión, credenciales incorrectas.';
      swal({
        title: 'Error',
        text: msg,
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            className: 'btn btn-danger',
            closeModal: true,
          },
        },
      });
    } else {
      const jwt = response.token;
      localStorage.setItem('token', jwt);
      navigate('/home');
    }
  };

  return (
    <div className='hold-transition login-page'>
      <div className='login-box'>
        <div className='login-logo'>
          <Link to={'#'}>
            <b>Iniciar</b> Sesión
          </Link>
        </div>
        <div className='card'>
          <div className='card-body login-card-body'>
            <p className='login-box-msg'>
              Bienvenido, ingrese sus credenciales.
            </p>
            <form onSubmit={onSubmit}>
              <div className='input-group mb-3'>
                <input
                  type='idUser'
                  className='form-control'
                  placeholder='número de usuario'
                  id='idUser'
                  name='idUser'
                  value={idUser}
                  onChange={onChange}
                  required
                />
                <div className='input-group-append'>
                  <div className='input-group-text'>
                    <span className='fas fa-user' />
                  </div>
                </div>
              </div>
              <div className='input-group mb-3'>
                <input
                  type='password'
                  className='form-control'
                  placeholder='Password'
                  id='password'
                  name='password'
                  value={password}
                  onChange={onChange}
                  required
                />
                <div className='input-group-append'>
                  <div className='input-group-text'>
                    <span className='fas fa-lock' />
                  </div>
                </div>
              </div>
              <div className='social-auth-links text-center mb-3'>
                <button type='submit' className='btn btn-block btn-primary'>
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
