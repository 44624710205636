const baseURL = 'https://admin-parqueadero-backend-nodejs.onrender.com/api/v1';

class APIInvoke {
  async invokeGET(resource) {
    const data = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const url = `${baseURL}${resource}`;
    let response = await (await fetch(url, data)).json();
    return response;
  }

  async invokePATCH(resource, body) {
    const data = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${baseURL}${resource}`;
    let response = await (await fetch(url, data)).json();
    return response;
  }

  async invokePOST(resource, body) {
    const data = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${baseURL}${resource}`;
    let response = await (await fetch(url, data)).json();
    return response;
  }

  async invokeDELETE(resource) {
    const data = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${baseURL}${resource}`;
    let response = await (await fetch(url, data)).json();
    return response;
  }
}
export default APIInvoke;
