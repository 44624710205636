import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import APIInvoke from '../../utils/APIInvoke';

const apiInvoke = new APIInvoke();

const AgregarVehiculoContainer = ({ titulo }) => {
  const [vehiculo, setVehiculo] = useState({
    idVehicule: '',
    type: '',
    idPlace: '',
    observations: '',
  });
  const [disponible, setDisponible] = useState([]);

  const { idVehicule, type, idPlace, observations } = vehiculo;

  const onChange = (e) => {
    setVehiculo({
      ...vehiculo,
      [e.target.name]: e.target.value,
    });
  };
  const plazasDisponibles = async () => {
    const response = await apiInvoke.invokeGET(`/park/available`);
    setDisponible(response);
  };

  const agregarVehiculo = async () => {
    if (idVehicule.length > 6) {
      const msg = 'La placa del vehículo no puede ser mayor a 6 carácteres.';
      swal({
        title: 'Error',
        text: msg,
        icon: 'error',
        buttons: {
          confirm: {
            text: 'Ok',
            value: true,
            visible: true,
            className: 'btn btn-danger',
            closeModal: true,
          },
        },
      });
    } else {
      const data = {
        idVehicule: vehiculo.idVehicule,
        type: vehiculo.type,
        idPlace: vehiculo.idPlace,
        observations: vehiculo.observations,
      };
      const response = await apiInvoke.invokePOST(`/vehicule`, data);
      if (response.message === 'Vehicule registered') {
        const msg = 'Se ha agregado el vehículo.';
        swal({
          title: 'Exitoso',
          text: msg,
          icon: 'success',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: 'btn btn-success',
              closeModal: true,
            },
          },
        });
      } else {
        const msg =
          'No se ha podido guardar el vehículo, revise que la información sea correcta.';
        swal({
          title: 'Error',
          text: msg,
          icon: 'error',
          buttons: {
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
              className: 'btn btn-danger',
              closeModal: true,
            },
          },
        });
      }
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    agregarVehiculo();
  };
  useEffect(() => {
    plazasDisponibles();
    document.getElementById('idVehicule').focus();
  }, []);
  return (
    <div className='content p-0 mt-4 col-12 col-md-6 offset-md-3 shadow'>
      <div onSubmit={onSubmit}>
        <form>
          <div className='card'>
            <div className='card-header' style={{ backgroundColor: '#70747c' }}>
              <h3 className='card-title text-white'>{titulo}</h3>
            </div>
            <div className='card-body'>
              <div className='form-group'>
                <label htmlFor='inputName'>Placa del vehículo</label>
                <input
                  type='text'
                  className='form-control'
                  id='idVehicule'
                  name='idVehicule'
                  value={idVehicule}
                  onChange={onChange}
                  required
                />
              </div>
              <div className='form-group'>
                <label htmlFor='inputStatus'>Tipo de vehículo</label>
                <select
                  className='form-control custom-select'
                  id='type'
                  name='type'
                  value={type}
                  onChange={onChange}
                >
                  <option>Seleccione uno tipo de vehículo</option>
                  <option>Automovil</option>
                  <option>Motocicleta</option>
                </select>
              </div>
              <div className='form-group'>
                <label htmlFor='inputStatus'>Plaza</label>
                <select
                  className='form-control custom-select'
                  id='idPlace'
                  name='idPlace'
                  value={idPlace}
                  onChange={onChange}
                >
                  <option>Seleccione una plaza disponible</option>
                  {disponible.map((item) => (
                    <option key={item.idPlace}>{item.idPlace}</option>
                  ))}
                </select>
              </div>
              <div className='form-group'>
                <label htmlFor='inputDescription'>Observaciones</label>
                <textarea
                  className='form-control'
                  style={{ resize: 'none' }}
                  id='observations'
                  name='observations'
                  value={observations}
                  onChange={onChange}
                  rows={4}
                  required
                />
              </div>
              <button type='submit' className='btn btn-block btn-primary'>
                Agregar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgregarVehiculoContainer;
